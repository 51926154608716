import { Interfaces } from '@configur-tech/upit-core-types';
import { FormQuestionDisplayType } from '@configur-tech/upit-core-types/lib/enums';
import React, { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Devices from '../../../enums/DeviceSize';
import QuestionProps from '../../../interfaces/QuestionProps';
import {
  InnerInputWrapper,
  InputWrapper,
  TextStyledSubHeader,
} from '../../../Theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../atoms/FeatureButton/FeatureButton';

const MultipleChoiceWrapper = styled(InputWrapper)`
  min-height: 10vh;
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    width: 95vw;
  }
`;

const ButtonsInnerWrapper = styled(InnerInputWrapper)`
  display: flex;
  flex-wrap: wrap;

  > div {
    padding-bottom: ${({ theme }) => theme.padding.large};
    margin-right: ${({ theme }) => theme.margin.xxxlarge};
    > button > div > p {
      font-size: ${({ theme }) => theme.typography.sizes.h3};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    > div {
      margin-right: ${({ theme }) => theme.margin.xxxlarge};
    }
  }
  @media only screen and (max-width: ${Devices.LARGE_PHONE}) {
    justify-content: space-around;
    > div {
      margin-right: 0;
      > button > div > p {
        font-size: ${({ theme }) => theme.typography.sizes.h5};
      }
    }
  }
`;

const MultipleChoice: FC<QuestionProps> = ({
  question,
  value,
  finalQuestion,
  setValue,
  markQuestionComplete,
  buttonColour,
}) => {
  const themeContext = useContext(ThemeContext);
  const isMobile = window.innerWidth < parseFloat(Devices.LARGE_PHONE);
  const isTablet = window.innerWidth < parseFloat(Devices.TABLET_PORTRAIT);
  const buttonText = finalQuestion ? 'Finish' : 'Next';
  const isActive = (value, text) => {
    return String(value).includes(String(text));
  };

  const canProceed = question.field.dataValidation?.constraints?.isRequired
    ? !!value
    : true;

  const setButtonSize = () => {
    return isMobile
      ? FeatureButtonSize.WIDE_LARGE
      : isTablet
      ? FeatureButtonSize.WIDE_LARGE
      : FeatureButtonSize.WIDE_XLARGE;
  };

  const handleMCClick = (inputValue: string) => {
    setValue(value === inputValue ? '' : inputValue);
  };

  return question.displayType === FormQuestionDisplayType.BOOLEAN ? (
    <MultipleChoiceWrapper>
      <TextStyledSubHeader>{question.question}</TextStyledSubHeader>

      <ButtonsInnerWrapper>
        <FeatureButton
          isActive={isActive(value, true)}
          size={setButtonSize()}
          color={
            isActive(value, true)
              ? themeContext.colors.general.yellow
              : themeContext.colors.general.blue
          }
          text={'Yes'}
          action={() => setValue(value !== true)}
        />
        <FeatureButton
          isActive={isActive(value, false)}
          size={setButtonSize()}
          color={
            isActive(value, false)
              ? themeContext.colors.general.yellow
              : themeContext.colors.general.blue
          }
          text={'No'}
          action={() => setValue(value === false)}
        />
      </ButtonsInnerWrapper>
      <FeatureButton
        action={markQuestionComplete}
        isDisabled={!canProceed}
        size={FeatureButtonSize.WIDE}
        color={buttonColour}
        text={buttonText}
      />
    </MultipleChoiceWrapper>
  ) : (
    <MultipleChoiceWrapper>
      <TextStyledSubHeader>{question.question}</TextStyledSubHeader>

      <ButtonsInnerWrapper>
        {(question.field.dataValidation?.constraints
          ?.listValues as Interfaces.ListValue[])?.map((option, index) => {
          return (
            <FeatureButton
              key={index}
              size={setButtonSize()}
              isActive={isActive(value, option.value)}
              text={option.value}
              color={
                isActive(value, option.value)
                  ? themeContext.colors.general.yellow
                  : themeContext.colors.general.blue
              }
              action={() => handleMCClick(option.value)}
            />
          );
        })}
      </ButtonsInnerWrapper>
      <FeatureButton
        action={markQuestionComplete}
        isDisabled={!canProceed}
        size={FeatureButtonSize.WIDE}
        color={buttonColour}
        text={buttonText}
      />
    </MultipleChoiceWrapper>
  );
};

export default MultipleChoice;
