export const handleEnterPress = (
  event: KeyboardEvent,
  canProceed: boolean,
  markQuestionComplete: () => void,
): void => {
  if (event.code === 'Enter' && canProceed) {
    event.preventDefault();
    markQuestionComplete();
  }
};
