import { Enums } from '@configur-tech/upit-core-types';
import {
  FormQuestionDisplayType,
  FormQuestionStatus,
} from '@configur-tech/upit-core-types/lib/enums';
import ReactFullpage from '@fullpage/react-fullpage';
import { DateTime } from 'luxon';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { FormContext, FormOutputWithTheme } from '../../../AppContext';
import Devices from '../../../enums/DeviceSize';
import FormService from '../../../services/FormService';
import { InputWrapper, TextStyledSubHeader } from '../../../Theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../atoms/FeatureButton/FeatureButton';
import Loading from '../loading/Loading';
import QuestionSelect from '../QuestionSelect/QuestionSelect';

const SlideInnerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.system.white};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a,
  a:hover,
  a:focus,
  a:active {
    color: ${({ theme }) => theme.colors.system.offWhite};
    text-decoration: none;
    cursor: pointer;
  }
`;

const StartFinishPageWrapper = styled(InputWrapper)`
  align-items: center;
  justify-content: center;
  text-align: center;
  > p {
    font-size: 1.8em;
    padding: ${({ theme }) => theme.padding.xxxlarge};
    margin: 0;
    @media only screen and (max-width: ${Devices.LARGE_PHONE}) {
      font-size: ${({ theme }) => theme.typography.sizes.h4};
    }
  }
  > div {
    align-self: center;
    padding: ${({ theme }) => theme.padding.xxxlarge};
  }
`;

const MainPage: FC<{
  isFormComplete: boolean;
  setIsFormComplete: (value: boolean) => void;
}> = ({ setIsFormComplete, isFormComplete }) => {
  const { formState, setFormState } = useContext(FormContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const questionDetails = formState.questionGroups
    .map((questionGroup) => questionGroup.questions)
    .flat();
  const finalQuestionId = questionDetails
    .filter((q) => q.displayType !== FormQuestionDisplayType.HIDDEN)
    ?.reverse()[0]._id;
  const themeContext = useContext(ThemeContext);
  const location = useLocation();
  const activeQuestionAnchor = location.hash.substring(1);
  const initialiseFormValues = () => {
    const valuesArray = {};
    questionDetails.forEach(
      (question) => (valuesArray[question._id] = question.value),
    );
    return valuesArray;
  };
  const [formValues, setFormValues] = useState<
    Record<string, string | string[] | boolean>
  >(initialiseFormValues());

  const buttonColour =
    (formState as FormOutputWithTheme)?.theme?.colours?.tertiary ||
    themeContext.colors.general.green;

  const updateQuestionStateValue = (groupName, questionId, newValue) => {
    const group = formState.questionGroups.find(
      (group) => group.groupName === groupName,
    );
    const question = group?.questions.find(
      (question) => question._id === questionId,
    );
    if (question) {
      question.value = newValue;
      setFormState(formState);
    }
  };

  useEffect(() => {
    if (
      questionDetails.filter((x) => x.status === FormQuestionStatus.COMPLETED)
        .length === questionDetails.length
    ) {
      setIsFormComplete(true);
    }
  }, [activeQuestionAnchor, questionDetails]);

  const completeQuestion = (groupName, questionId) => {
    const group = formState.questionGroups.find(
      (group) => group.groupName === groupName,
    );
    const question = group?.questions.find(
      (question) => question._id === questionId,
    );
    if (question) {
      question.status = FormQuestionStatus.COMPLETED;
    }
    setFormState(formState);
  };

  const generateAnswersObject = () => {
    const valuesObject = {};
    questionDetails.forEach((question) => {
      if (Array.isArray(question.value)) {
        valuesObject[question.field.name] = question.value.join(', ');
      } else if (question.setValueAsSubmittedDate) {
        const format =
          question.field.dataValidation?.constraints?.format ||
          Enums.DateFormat.DATE_SHORT;
        valuesObject[question.field.name] = DateTime.now().toFormat(format);
      } else {
        valuesObject[question.field.name] = question.value;
      }
    });

    return valuesObject;
  };

  const postCompletedQuestions = async () => {
    setLoading(true);
    try {
      await FormService.postForm(formState._id, formState.datasetMetaId, [
        generateAnswersObject(),
      ]);
      setSubmitted(true);
    } catch (err) {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      questionDetails
        .filter(
          (question) => question.displayType !== FormQuestionDisplayType.HIDDEN,
        )
        .every((question) => question.status === FormQuestionStatus.COMPLETED)
    ) {
      postCompletedQuestions();
    }
  }, [activeQuestionAnchor]);

  const getAnchors = () => {
    const allQuestionIds = questionDetails
      .filter(
        (question) =>
          question.displayType !== Enums.FormQuestionDisplayType.HIDDEN,
      )
      .map((questionDetail) => questionDetail._id);

    return ['welcome', ...allQuestionIds, 'finish'];
  };

  if (loading) {
    return <Loading loading={true} text={'Submitting Form'} />;
  }

  return (
    <ReactFullpage
      licenseKey={process.env['REACT_APP_FULL_PAGE_LICENSE_KEY']}
      anchors={getAnchors()}
      normalScrollElements={'.scrollable-dropdown'}
      render={({ fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            {!submitted && !error && (
              <>
                <div className="section">
                  <SlideInnerWrapper>
                    <StartFinishPageWrapper>
                      <TextStyledSubHeader>
                        {formState.intro?.title}
                      </TextStyledSubHeader>
                      <p>{formState.intro?.body}</p>
                      <FeatureButton
                        action={() => {
                          fullpageApi.moveSectionDown();
                        }}
                        size={FeatureButtonSize.WIDE}
                        color={buttonColour}
                        text={formState.intro?.buttonText}
                      />
                    </StartFinishPageWrapper>
                  </SlideInnerWrapper>
                </div>

                {formState.questionGroups.map((group) => {
                  return group.questions
                    .filter(
                      (question) =>
                        question.displayType !==
                        Enums.FormQuestionDisplayType.HIDDEN,
                    )
                    .map((questionButton, index) => {
                      return (
                        <div className="section" key={`section-${index}`}>
                          <SlideInnerWrapper>
                            <QuestionSelect
                              question={questionButton}
                              finalQuestion={
                                questionButton._id === finalQuestionId
                              }
                              value={formValues[questionButton._id]}
                              isFormComplete={isFormComplete}
                              finishForm={() => {
                                fullpageApi?.setAllowScrolling(false);
                                fullpageApi?.setKeyboardScrolling(false);
                              }}
                              disableScroll={() => {
                                fullpageApi?.setAllowScrolling(false, 'down');
                                fullpageApi?.setKeyboardScrolling(
                                  false,
                                  'down',
                                );
                              }}
                              disableAllScroll={() => {
                                fullpageApi?.setAllowScrolling(false, 'up');
                                fullpageApi?.setKeyboardScrolling(false, 'up');
                                fullpageApi?.setAllowScrolling(false, 'down');
                                fullpageApi?.setKeyboardScrolling(
                                  false,
                                  'down',
                                );
                              }}
                              enableScrollUp={() => {
                                fullpageApi?.setAllowScrolling(true, 'up');
                                fullpageApi?.setKeyboardScrolling(true, 'up');
                              }}
                              markQuestionComplete={() => {
                                fullpageApi.moveSectionDown();
                                completeQuestion(
                                  group.groupName,
                                  questionButton._id,
                                );
                              }}
                              setValue={(value) => {
                                updateQuestionStateValue(
                                  group.groupName,
                                  questionButton._id,
                                  value,
                                );
                                setFormValues({
                                  ...formValues,
                                  [questionButton._id]: value,
                                });
                              }}
                              buttonColour={buttonColour}
                            />
                          </SlideInnerWrapper>
                        </div>
                      );
                    });
                })}
              </>
            )}

            <div className="section">
              <SlideInnerWrapper>
                <StartFinishPageWrapper>
                  <TextStyledSubHeader>
                    {error ? 'Something went wrong' : formState.outro?.title}
                  </TextStyledSubHeader>
                  <p>
                    {error
                      ? 'A problem has occurred. Please try again later.'
                      : formState.outro?.body}
                  </p>
                </StartFinishPageWrapper>
              </SlideInnerWrapper>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
};

export default MainPage;
