import React, { FC, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { FormContext, FormOutputWithTheme } from './AppContext';
import Routes from './routes/Routes';
import { initialFormState } from './state/initial-form-state';
import Theme from './Theme';

const App: FC = () => {
  const [formState, setFormState] = useState<FormOutputWithTheme>(
    initialFormState as FormOutputWithTheme,
  );

  return (
    <Theme>
      <Router>
        <HelmetProvider>
          <FormContext.Provider value={{ formState, setFormState }}>
            <div className="App">
              <Routes />
            </div>
          </FormContext.Provider>
        </HelmetProvider>
      </Router>
    </Theme>
  );
};

export default App;
