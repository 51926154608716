import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorContainer from '../pages/ErrorContainer';
import FormContainer from '../pages/FormContainer';

const Routes: FC = () => {
  return (
    <Switch>
      {/*Form*/}
      <Route
        path={`/:projectId/:formId`}
        exact={true}
        component={FormContainer}
      />
      {/*Error*/}
      <Route path={`/`} exact={true} component={ErrorContainer} />
    </Switch>
  );
};

export default Routes;
