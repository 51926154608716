import React, { CSSProperties, FC } from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import { Accordion, Dropdown, Input, TextArea } from 'semantic-ui-react';
import styled, { DefaultTheme, ThemeProvider } from 'styled-components';
import Devices from './enums/DeviceSize';

export const defaultTheme: DefaultTheme = {
  colors: {
    system: {
      darkBlue: '#272C34',
      darkBlueHighlight: '#3D4857',
      darkBlueShadow: '#1B1F24',
      darkGrey: '#808080',
      darkOffBlack: '#23282F',
      grey: '#E4E4E4',
      navy: '#01203A',
      offBlack: '#2F3640',
      offWhite: '#F0EEEE',
      white: '#F8F8F8',
    },
    general: {
      blue: '#3498DB',
      green: '#2ECC71',
      purple: '#7d317d',
      red: '#E74C3C',
      sea: '#3C6382',
      yellow: '#F7C535',
    },
  },

  typography: {
    header: `
      font-family: 'Roboto', 'sans-serif';
      font-weight: bold;
      margin: 0;
      padding: 0;
    `,
    body: `
      font-size: 1em;
      font-family: 'Lato', 'sans-serif';
      font-weight: regular;
      margin: 0 0 1em 0;
      padding: 0;
    `,
    sizes: {
      p: '1.0em',
      smaller: '0.85em',
      small: '0.75em',
      tiny: '0.65em',
      h1: '1.8em',
      h2: '1.6em',
      h3: '1.4em',
      h4: '1.2em',
      h5: '1.0em',
    },
  },

  margin: {
    small: '5px',
    standard: '10px',
    large: '15px',
    xlarge: '20px',
    xxlarge: '25px',
    xxxlarge: '30px',
  },

  padding: {
    small: '5px',
    standard: '10px',
    large: '15px',
    xlarge: '20px',
    xxlarge: '25px',
    xxxlarge: '30px',
  },

  borders: {
    radius: '5px',
  },
};

export const StyledText = styled.p`
  ${defaultTheme.typography.body};
  font-size: ${defaultTheme.typography.sizes.p};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledSubHeader = styled(StyledText)`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h4};
  margin-bottom: ${defaultTheme.margin.standard};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledBodySubHeader = styled(StyledText)`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h5};
  margin-bottom: ${defaultTheme.margin.standard};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledH1 = styled.h1`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h1};
  margin-bottom: ${defaultTheme.margin.large};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledH2 = styled.h2`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h2};
  margin-bottom: ${defaultTheme.margin.large};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledH3 = styled.h3`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h3};
  margin-bottom: ${defaultTheme.margin.large};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledH4 = styled.h4`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h4};
  margin-bottom: ${defaultTheme.margin.standard};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledH5 = styled.h5`
  ${defaultTheme.typography.header};
  font-size: ${defaultTheme.typography.sizes.h5};
  margin-bottom: ${defaultTheme.margin.standard};
  color: ${defaultTheme.colors.system.offBlack};
`;

export const StyledInputHeader = styled(StyledText)`
  text-transform: uppercase;

  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.smaller};
  color: ${({ theme }) => theme.colors.system.offBlack};

  margin-bottom: ${({ theme }) => theme.margin.small};
`;

export const StyledInput = styled(Input)`
  width: 250px;
  max-width: 100%;

  > input {
    text-align: center !important;

    &:focus {
      border-color: ${({ theme }) => theme.colors.general.blue};
      outline: none;
    }
  }
`;

export const StyledTextArea = styled(TextArea)`
  width: 400px;
  max-width: 100%;

  padding: ${({ theme }) => `${theme.padding.standard} ${theme.margin.large}`};

  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  &:focus {
    border-color: ${({ theme }) => theme.colors.general.blue};
    outline: none;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: 250px;

  margin: ${({ theme }) => `${theme.margin.standard} 0 ${theme.margin.xlarge}`};

  .menu {
    max-height: 150px !important;
  }
`;

export const StyledDropdownWide = styled(Dropdown)`
  width: 400px;

  margin: ${({ theme }) => `${theme.margin.standard} 0 ${theme.margin.xlarge}`};
`;

export const StyledDropdownUltraWide = styled(Dropdown)`
  width: 650px;

  margin: ${({ theme }) => `${theme.margin.standard} 0 ${theme.margin.xlarge}`};
`;

export const StageWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge}`};
`;

export const StageBodyText = styled(StyledText)`
  width: 100%;
  max-width: 700px;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
  text-align: center;
`;

export const StyledAccordion = styled(Accordion)`
  min-width: 350px;

  background-color: ${({ theme }) => theme.colors.system.white};
  border: 1px solid ${({ theme }) => theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const StyledAccordionTitle = styled(Accordion.Title)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.large}`} !important;

  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};

  > p {
    margin: 0;
  }
`;

export const TextStyledSubHeader = styled(StyledSubHeader)`
  font-size: 2.5em;
  padding: ${({ theme }) => theme.padding.standard};
  margin: 0;
  @media only screen and (max-width: ${Devices.LARGE_PHONE}) {
    font-size: ${({ theme }) => theme.typography.sizes.h2};
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  > .input {
    width: 800px;
    max-width: 800px;
    font-size: ${({ theme }) => theme.typography.sizes.h1};
  }

  > div {
    padding: ${({ theme }) => theme.padding.standard};
    align-self: flex-end;
  }
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    width: 95vw;
    > .input {
      max-width: 95vw;
    }
  }
  @media only screen and (max-width: ${Devices.LARGE_PHONE}) {
    > .input {
      font-size: ${({ theme }) => theme.typography.sizes.h3};
    }
  }
`;

export const InnerInputWrapper = styled.div`
  width: 800px;
  max-width: 800px;
  padding: ${({ theme }) => theme.padding.standard};
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    width: 100%;
  }
`;

export const DefaultPopupStyles: CSSProperties = {
  border: 'none',
  borderRadius: defaultTheme.borders.radius,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
};

export const WarningWrapper = styled.div<{
  hasError: boolean;
  errorText: string;
}>`
  ${({ hasError, errorText }) =>
    hasError && errorText && errorText.length > 0
      ? `padding: ${({ theme }) => theme.padding.standard};`
      : `display:none;`};
`;

const Theme: FC = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>
    <GoogleFontLoader
      fonts={[
        { font: 'Lato', weights: [400] },
        { font: 'Roboto', weights: [700] },
      ]}
    />
    {children}
  </ThemeProvider>
);

export default Theme;
