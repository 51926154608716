import { Interfaces } from '@configur-tech/upit-core-types';
import { createContext, useContext } from 'react';
import { initialFormState } from './state/initial-form-state';

export interface FormOutputWithTheme extends Interfaces.FormOutput {
  theme: Interfaces.Theme;
}

export type FormContextType = {
  formState: FormOutputWithTheme;
  setFormState: (form: FormOutputWithTheme) => void;
};

export const FormContext = createContext<FormContextType>({
  formState: initialFormState as FormOutputWithTheme,
  setFormState: () => console.warn('No form provider'),
});

export const useFormContext = (): FormContextType => useContext(FormContext);
