import React, { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import FeatureButton, {
  FeatureButtonSize,
} from '../../atoms/FeatureButton/FeatureButton';
import { ReactComponent as ConfigurIcon } from '../../assets/codex-icon.svg';

const StyledConfigurIcon = styled(ConfigurIcon)`
  margin-left: ${({ theme }) => theme.margin.standard};
`;

const PoweredBy: FC = () => {
  const themeContext = useContext(ThemeContext);
  return (
    <a href="https://www.configur.tech/" target="_blank" rel="noreferrer">
      <FeatureButton
        size={FeatureButtonSize.WIDE}
        color={themeContext.colors.system.navy}
        faceStyle={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
        text={'POWERED BY'}
        icon={<StyledConfigurIcon width="30px" height="30px" />}
      />
    </a>
  );
};

export default PoweredBy;
