import React, { FC, useEffect, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import styled from 'styled-components';
import Devices from '../../../enums/DeviceSize';
import QuestionProps from '../../../interfaces/QuestionProps';
import {
  InnerInputWrapper,
  InputWrapper,
  StyledDropdownUltraWide,
  TextStyledSubHeader,
} from '../../../Theme';
import { handleEnterPress } from '../../../util/handleEnterPress';
import FeatureButton, {
  FeatureButtonSize,
} from '../../atoms/FeatureButton/FeatureButton';

const StyledFormsDropdownUltraWide = styled(StyledDropdownUltraWide)`
  width: 100%;
  min-height: 10vh;
`;

const DropdownInnerWrapper = styled(InnerInputWrapper)`
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    width: 95vw;
  }
  > .ui.selection.dropdown {
    padding: ${({ theme }) => theme.padding.large};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    font-size: ${({ theme }) => theme.typography.sizes.h1};
    @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
      font-size: ${({ theme }) => theme.typography.sizes.h4};
    }
    > i.dropdown {
      position: static;
      margin-left: auto;
    }
  }
`;

const Select: FC<QuestionProps> = ({
  question,
  value,
  finalQuestion,
  setValue,
  markQuestionComplete,
  disableAllScroll,
  enableScrollUp,
  buttonColour,
}) => {
  const buttonText = finalQuestion ? 'Finish' : 'Next';
  const [questionOptions, setQuestionOptions] = useState<DropdownItemProps[]>(
    [],
  );

  const canProceed = question.field.dataValidation?.constraints?.isRequired
    ? !!value
    : true;

  const formatQuestionOptions = (question) => {
    return question.field.dataValidation?.constraints?.listValues?.map(
      (lv, i) => {
        return {
          key: `option-${lv.value}-${i}`,
          text: lv.value,
          value: lv.value,
        };
      },
    );
  };

  useEffect(() => {
    setQuestionOptions(formatQuestionOptions(question));
  }, [question]);

  // TODO - ADD MULTIPLE SUPPORT IN FE
  // const isMultiple =
  //   question && question?.numberSelectable && question.numberSelectable > 1;

  return (
    <InputWrapper>
      <TextStyledSubHeader>{question.question}</TextStyledSubHeader>

      <DropdownInnerWrapper>
        <StyledFormsDropdownUltraWide
          clearable
          className={'scrollable-dropdown'}
          placeholder={'Please select an option'}
          search
          selection
          options={questionOptions}
          value={value || ''}
          onChange={(e, data) => {
            setValue(data.value);
          }}
          onKeyUp={(event: KeyboardEvent) => {
            handleEnterPress(event, canProceed, markQuestionComplete);
          }}
          onOpen={() => {
            if (disableAllScroll) {
              disableAllScroll();
            }
          }}
          onClose={() => {
            if (enableScrollUp) {
              enableScrollUp();
            }
          }}
        />
      </DropdownInnerWrapper>
      <FeatureButton
        action={markQuestionComplete}
        isDisabled={!canProceed}
        size={FeatureButtonSize.WIDE}
        color={buttonColour}
        text={buttonText}
      />
    </InputWrapper>
  );
};

export default Select;
