import { Interfaces } from '@configur-tech/upit-core-types';

export const initialFormState: Interfaces.Form = {
  name: 'Configur',
  avatar:
    'https://stage.d3b8n6in2yqzyt.amplifyapp.com/static/media/codex-icon.df7424b4.svg',
  intro: {
    title: `Let's get started`,
    body: `Just tap the button below to start completing this form.`,
    buttonText: 'Get Started',
  },
  outro: {
    title: 'Form Submitted',
    body: `Your response has been received, thanks!`,
  },
  meta: {
    created: new Date(),
    createdBy: '',
    lastUpdated: new Date(),
    lastUpdatedBy: '',
  },
  organisationId: '',
  datasetMetaId: '',
  access: {
    manage: { organisation: [], team: [], user: [] },
    edit: { organisation: [], team: [], user: [] },
    view: { organisation: [], team: [], user: [] },
  },

  questionGroups: [],
};
