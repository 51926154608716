import {
  FormQuestionDisplayType,
  FormQuestionStatus,
} from '@configur-tech/upit-core-types/lib/enums';
import { FormQuestion } from '@configur-tech/upit-core-types/lib/interfaces';
import React, { FC, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormContext } from '../../../AppContext';
import DatePickerSelector from '../QuestionTypes/DatePickerSelector';
import MultipleChoice from '../QuestionTypes/MultipleChoice';
import Select from '../QuestionTypes/Select';
import Text from '../QuestionTypes/Text';
import TextArea from '../QuestionTypes/TextArea';

export interface QuestionSelectProps {
  question: FormQuestion;
  setValue: (value: string | string[] | boolean) => void;
  disableScroll: () => void;
  disableAllScroll: () => void;
  enableScrollUp: () => void;
  markQuestionComplete: () => void;
  finalQuestion: boolean;
  isFormComplete: boolean;
  finishForm: () => void;
  value: string | string[] | boolean;
  buttonColour?: string;
}

const QuestionSelect: FC<QuestionSelectProps> = ({
  question,
  value,
  finalQuestion,
  setValue,
  markQuestionComplete,
  disableScroll,
  disableAllScroll,
  enableScrollUp,
  finishForm,
  isFormComplete,
  buttonColour,
}) => {
  const location = useLocation();
  const activeQuestionAnchor = location.hash.substring(1);
  const { formState } = useContext(FormContext);
  const questionDetails = formState.questionGroups
    .map((questionGroup) => questionGroup.questions)
    .flat();

  const firstIncompleteQuestionIndex = questionDetails.findIndex(
    (question) => question.status === FormQuestionStatus.NOT_COMPLETED,
  );
  const firstIncompleteQuestionId =
    questionDetails[firstIncompleteQuestionIndex] &&
    questionDetails[firstIncompleteQuestionIndex]._id;
  const isOnLastQuestion = activeQuestionAnchor === firstIncompleteQuestionId;

  useEffect(() => {
    disableScroll();
  }, [isOnLastQuestion, disableScroll]);

  useEffect(() => {
    if (isFormComplete) {
      finishForm();
    }
  }, [isFormComplete, finishForm]);

  switch (question.displayType) {
    case FormQuestionDisplayType.TEXT_AREA:
      return (
        <TextArea
          question={question}
          value={value}
          finalQuestion={finalQuestion}
          setValue={setValue}
          markQuestionComplete={markQuestionComplete}
          buttonColour={buttonColour}
        />
      );
    case FormQuestionDisplayType.SELECT:
      return (
        <Select
          question={question}
          value={value}
          finalQuestion={finalQuestion}
          setValue={setValue}
          markQuestionComplete={markQuestionComplete}
          disableAllScroll={disableAllScroll}
          enableScrollUp={enableScrollUp}
          buttonColour={buttonColour}
        />
      );
    case FormQuestionDisplayType.DATE:
      return (
        <DatePickerSelector
          question={question}
          value={value}
          finalQuestion={finalQuestion}
          setValue={setValue}
          markQuestionComplete={markQuestionComplete}
          dateFormat={question.field.dataValidation?.constraints?.format}
          buttonColour={buttonColour}
        />
      );
    case FormQuestionDisplayType.MULTIPLE_CHOICE:
      return (
        <MultipleChoice
          question={question}
          finalQuestion={finalQuestion}
          value={value}
          setValue={setValue}
          markQuestionComplete={markQuestionComplete}
          buttonColour={buttonColour}
        />
      );
    case FormQuestionDisplayType.BOOLEAN:
      return (
        <MultipleChoice
          question={question}
          value={value}
          finalQuestion={finalQuestion}
          setValue={setValue}
          markQuestionComplete={markQuestionComplete}
          buttonColour={buttonColour}
        />
      );
    case FormQuestionDisplayType.TEXT:
      return (
        <Text
          question={question}
          value={value}
          finalQuestion={finalQuestion}
          setValue={setValue}
          markQuestionComplete={markQuestionComplete}
          buttonColour={buttonColour}
        />
      );
    case FormQuestionDisplayType.NUMBER:
      return (
        <Text
          question={question}
          value={value}
          finalQuestion={finalQuestion}
          setValue={setValue}
          markQuestionComplete={markQuestionComplete}
          buttonColour={buttonColour}
        />
      );

    default:
      return (
        <Text
          question={question}
          value={value}
          finalQuestion={finalQuestion}
          setValue={setValue}
          markQuestionComplete={markQuestionComplete}
          buttonColour={buttonColour}
        />
      );
  }
};

export default QuestionSelect;
