import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FormQuestionStatus } from '@configur-tech/upit-core-types/lib/enums';

interface StatusIconProps {
  status: FormQuestionStatus;
  isQuestionActive: boolean;
}

const StatusIcon: FC<StatusIconProps> = ({ status, isQuestionActive }) => {
  const themeContext = useContext(ThemeContext);
  let color;
  switch (status) {
    case FormQuestionStatus.COMPLETED:
      color = themeContext.colors.general.green;
      break;
    case FormQuestionStatus.NOT_COMPLETED:
      isQuestionActive
        ? (color = themeContext.colors.system.offWhite)
        : (color = themeContext.colors.system.grey);
      break;

    default:
      color = themeContext.colors.general.yellow;
  }

  return <FontAwesomeIcon icon={faCheckCircle} color={color} />;
};

export default StatusIcon;
