import React, { FC, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import styled, { ThemeContext } from 'styled-components';
import ConfigurLogo from '../common/assets/codex-icon.svg';
import FeatureButton, {
  FeatureButtonSize,
} from '../common/atoms/FeatureButton/FeatureButton';
import PoweredBy from '../common/atoms/PoweredBy/PoweredBy';
import Devices from '../enums/DeviceSize';
import { TextStyledSubHeader } from '../Theme';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > img {
    width: 150px;
  }

  > p:last-of-type {
    margin-bottom: ${({ theme }) => theme.margin.xxxlarge};
  }
`;

const PoweredByWrapper = styled.div`
  right: ${({ theme }) => theme.margin.xlarge};
  position: fixed;
  bottom: ${({ theme }) => theme.margin.xlarge};
  z-index: 99;
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    display: none;
  }
`;

const ErrorContainer: FC = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <Helmet>
        <title>{`Forms Powered by Configur`}</title>
      </Helmet>

      <Wrapper>
        <img alt={'Configur Logo'} src={ConfigurLogo} />

        <TextStyledSubHeader>Want to create your own form?</TextStyledSubHeader>
        <p>
          Sign up to Configur today to easily store, secure and share your data
          across Forms, APIs, Data Views and more!
        </p>
        <FeatureButton
          action={() => window.location.replace('https://configur.co.uk')}
          size={FeatureButtonSize.WIDE}
          color={themeContext.colors.general.green}
          text={'Sign Up Now'}
        />
      </Wrapper>
      <PoweredByWrapper>
        <PoweredBy />
      </PoweredByWrapper>
    </>
  );
};

export default ErrorContainer;
