import { Enums, Interfaces } from '@configur-tech/upit-core-types';
import { FormQuestionStatus } from '@configur-tech/upit-core-types/lib/enums';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  CSSProperties,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Accordion } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import { FormContext, FormOutputWithTheme } from '../../../AppContext';
import Devices from '../../../enums/DeviceSize';
import PoweredBy from '../../atoms/PoweredBy/PoweredBy';
import StatusIcon from '../../atoms/StatusIcon/StatusIcon';

const StyledAccordionContent = styled(({ ...props }) => {
  delete props.isQuestionActive;
  return <Accordion.Content {...props} />;
})`
  width: 100%;
  padding: inherit;
  ${({ isQuestionActive, theme }) =>
    isQuestionActive
      ? `background-color: ${theme.colors.system.grey};
      > a > div > svg {
        color:  ${theme.colors.system.white};
      }`
      : `background-color: ${theme.colors.system.offWhite}
    `};

  > a {
    color: inherit;
  }
  .blockedCursor {
    cursor: not-allowed;
  }
  :hover {
    background-color: ${({ theme }) => `${theme.colors.system.grey}`};
    > a > div > svg {
      color: ${({ theme }) => theme.colors.system.white};
    }
  }
`;

const AccordionInnerText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  > p {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding-right: ${({ theme }) => theme.padding.standard};
  }
`;

const StyledAccordionTitle = styled(({ ...props }) => {
  delete props.isGroupActive;
  return <Accordion.Title {...props} />;
})`
  width: 100%;

  ${({ accordionActiveColour, isGroupActive, theme }) =>
    isGroupActive
      ? `background-color: ${
          accordionActiveColour
            ? accordionActiveColour
            : theme.colors.general.blue
        } ; > p{color: ${theme.colors.system.white}}`
      : `background-color: ${theme.colors.system.grey}; > p{color: ${theme.colors.system.offBlack}}`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > p {
    padding: ${({ theme }) => `${theme.padding.standard} `};
    margin: 0;
  }
  > .svg-inline--fa {
    margin: ${({ theme }) => `${theme.margin.standard}`};
    width: ${({ theme }) => theme.typography.sizes.h4};
    height: ${({ theme }) => theme.typography.sizes.h4};
  }
`;

const AccordionWrapper = styled.div`
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 90px);
  }
`;

const NavPoweredByWrapper = styled.div`
  display: none;
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    display: flex;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.margin.xlarge};
  }
`;

const NavItems: FC<{
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setIsVisible }) => {
  const themeContext = useContext(ThemeContext);
  const { formState } = useContext(FormContext);
  const location = useLocation();
  const [activeGroupIndex, setActiveGroupIndex] = useState<number>(0);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0);
  const isMobile = window.innerWidth < parseFloat(Devices.TABLET_PORTRAIT);
  const activeQuestionAnchor = location.hash.substring(1);

  const AccordionStyles: CSSProperties = {
    padding: themeContext.padding.standard,
  };

  const accordionActiveColour = (formState as FormOutputWithTheme)?.theme
    ?.colours?.secondary;

  const questionDetails = formState.questionGroups
    .map((questionGroup) => questionGroup.questions)
    .flat();

  const ActiveQuestionAnchorIndex = questionDetails.findIndex(
    (question) => question._id === activeQuestionAnchor,
  );

  const isWelcomeActive =
    questionDetails.filter(
      (questionDetail) => questionDetail._id === activeQuestionAnchor,
    ).length === 0;

  const isLinkEnabled = (questionId) =>
    questionDetails.findIndex((question) => question._id === questionId) <=
    ActiveQuestionAnchorIndex
      ? `#${questionId}`
      : undefined;

  const selectCursor = (questionId) => {
    if (questionId > ActiveQuestionAnchorIndex) {
      return `blockedCursor`;
    }
  };

  const findActiveGroupIndex = (data) =>
    data.findIndex(
      (group) =>
        group.questions.findIndex(
          (question) => question._id === activeQuestionAnchor,
        ) !== -1,
    );

  const findActiveQuestionIndex = (group) =>
    group
      ? group.questions.findIndex(
          (question) => question._id === activeQuestionAnchor,
        )
      : -1;

  useEffect(() => {
    const groupIndex = findActiveGroupIndex(formState.questionGroups);
    const questionIndex = findActiveQuestionIndex(
      formState.questionGroups[groupIndex],
    );
    setActiveGroupIndex(groupIndex);
    setActiveQuestionIndex(questionIndex);
  }, [activeQuestionAnchor]);

  const handleClick = (_, titleProps) => {
    const { index } = titleProps;

    const newIndex = activeGroupIndex === index ? -1 : index;

    setActiveGroupIndex(newIndex);
    setActiveQuestionIndex(-1);
  };

  const getGroupStatus = (questions: Interfaces.FormQuestionOutput[]) => {
    const numberOfQuestions = questions.filter(
      (question) =>
        question.displayType !== Enums.FormQuestionDisplayType.HIDDEN,
    ).length;
    const numberOfCompletedQuestions = questions.filter(
      (question) =>
        question.status === FormQuestionStatus.COMPLETED &&
        question.displayType !== Enums.FormQuestionDisplayType.HIDDEN,
    ).length;
    return `(${numberOfCompletedQuestions}/${numberOfQuestions})`;
  };

  return (
    <AccordionWrapper>
      <Accordion inverted fluid>
        <a href={'#welcome'}>
          <StyledAccordionTitle
            isGroupActive={isWelcomeActive}
            key={'-1'}
            accordionActiveColour={accordionActiveColour}
          >
            <p>{'Welcome'}</p>
          </StyledAccordionTitle>
        </a>
        {formState.questionGroups.map((group, groupIndex) => {
          const isGroupActive = activeGroupIndex === groupIndex;
          return (
            <div key={groupIndex}>
              <StyledAccordionTitle
                index={groupIndex}
                isGroupActive={isGroupActive}
                onClick={handleClick}
                accordionActiveColour={accordionActiveColour}
              >
                <p>
                  {group.groupName} {getGroupStatus(group.questions)}
                </p>
                <FontAwesomeIcon
                  icon={isGroupActive ? faChevronUp : faChevronDown}
                  color={
                    isGroupActive
                      ? themeContext.colors.system.offWhite
                      : themeContext.colors.system.offBlack
                  }
                />
              </StyledAccordionTitle>
              {group.questions
                .filter(
                  (question) =>
                    question.displayType !==
                    Enums.FormQuestionDisplayType.HIDDEN,
                )
                .map((questionButton, questionIndex) => {
                  const isQuestionActive =
                    questionIndex === activeQuestionIndex;
                  return (
                    <StyledAccordionContent
                      active={isGroupActive}
                      style={AccordionStyles}
                      isQuestionActive={isQuestionActive}
                      onClick={() => isMobile && setIsVisible(false)}
                      key={questionIndex}
                    >
                      <a
                        href={isLinkEnabled(questionButton._id)}
                        className={selectCursor(questionButton._id)}
                      >
                        <AccordionInnerText>
                          <p>{questionButton.question}</p>
                          <StatusIcon
                            status={questionButton.status}
                            isQuestionActive={isQuestionActive}
                          />
                        </AccordionInnerText>
                      </a>
                    </StyledAccordionContent>
                  );
                })}
            </div>
          );
        })}
      </Accordion>
      <NavPoweredByWrapper>
        <PoweredBy />
      </NavPoweredByWrapper>
    </AccordionWrapper>
  );
};

export default NavItems;
