import { Services } from '@configur-tech/upit-core-types';
import { SchemaFieldConstraints } from '@configur-tech/upit-core-types/lib/interfaces';
import React, { FC, useState } from 'react';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';
import Devices from '../../../enums/DeviceSize';
import QuestionProps from '../../../interfaces/QuestionProps';
import {
  InnerInputWrapper,
  InputWrapper,
  StyledTextArea,
  TextStyledSubHeader,
  WarningWrapper,
} from '../../../Theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../atoms/FeatureButton/FeatureButton';

const TextAreaInputWrapper = styled(InputWrapper)`
  > textarea {
    width: 800px;
    max-width: 800px;
    font-size: ${({ theme }) => theme.typography.sizes.h1};
  }

  > textarea {
    padding: ${({ theme }) => theme.padding.standard};
    align-self: flex-end;
  }
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    > textarea {
      width: 90vw;
    }
  }
  @media only screen and (max-width: ${Devices.LARGE_PHONE}) {
    > textarea {
      font-size: ${({ theme }) => theme.typography.sizes.h3};
      > div {
        > textarea {
          font-size: ${({ theme }) => theme.typography.sizes.h3};
        }
      }
    }
  }
`;

const LargeStyledTextArea = styled(StyledTextArea)`
  width: 100%;
  height: 15vh;
`;

const TextArea: FC<QuestionProps> = ({
  question,
  value,
  finalQuestion,
  setValue,
  markQuestionComplete,
  buttonColour,
}) => {
  const buttonText = finalQuestion ? 'Finish' : 'Next';
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const isValid = (
    value: string | number | boolean | string[],
    constraints?: SchemaFieldConstraints,
  ) => {
    if (!value && !question.field.dataValidation?.constraints?.isRequired) {
      return setHasError(false);
    }

    Services.validators.DataValidator.isText(value, constraints)
      ? setHasError(false)
      : setHasError(true);
  };

  const canProceed = question.field.dataValidation?.constraints?.isRequired
    ? !!(value && !hasError)
    : !hasError;

  return (
    <TextAreaInputWrapper>
      <TextStyledSubHeader>{question.question}</TextStyledSubHeader>
      <InnerInputWrapper>
        <LargeStyledTextArea
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          type={question.displayType}
          onBlur={() => {
            try {
              isValid(value, question.field.dataValidation?.constraints);
            } catch (error) {
              setHasError(error);
              setErrorText(error.message);
            }
          }}
        />
      </InnerInputWrapper>
      <WarningWrapper hasError={hasError} errorText={errorText}>
        <Message>
          <Message.Header>{errorText}</Message.Header>
        </Message>
      </WarningWrapper>
      <FeatureButton
        action={markQuestionComplete}
        isDisabled={!canProceed}
        size={FeatureButtonSize.WIDE}
        color={buttonColour}
        text={buttonText}
      />
    </TextAreaInputWrapper>
  );
};

export default TextArea;
