import { Enums, Interfaces, Services } from '@configur-tech/upit-core-types';
import React, { FC, useState } from 'react';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';
import Devices from '../../../enums/DeviceSize';
import QuestionProps from '../../../interfaces/QuestionProps';
import {
  InputWrapper,
  StyledInput,
  TextStyledSubHeader,
  WarningWrapper,
} from '../../../Theme';
import { handleEnterPress } from '../../../util/handleEnterPress';
import FeatureButton, {
  FeatureButtonSize,
} from '../../atoms/FeatureButton/FeatureButton';

const TextInputWrapper = styled(InputWrapper)`
  > .ui.input {
    height: 105px;
    @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
      height: 85px;
    }
    > input {
      height: 100%;
      padding: ${({ theme }) => theme.padding.large};
    }
  }
`;

const TextStyledInput = styled(StyledInput)`
  > input {
    text-align: left !important;
  }
`;

const Text: FC<QuestionProps> = ({
  question,
  value,
  finalQuestion,
  setValue,
  markQuestionComplete,
  buttonColour,
}) => {
  const buttonText = finalQuestion ? 'Finish' : 'Next';
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const isValid = (
    type: Enums.ValueDataType,
    value: string | number | boolean | string[],
    constraints?: Interfaces.SchemaFieldConstraints,
  ) => {
    if (type === Enums.ValueDataType.TEXT) {
      if (!value && !question.field.dataValidation?.constraints?.isRequired) {
        return setHasError(false);
      }

      if (Services.validators.DataValidator.isText(value, constraints)) {
        return setHasError(false);
      }

      setHasError(true);
      setErrorText('This is not valid text.');
    }

    if (type === Enums.ValueDataType.EMAIL) {
      if (!value && !question.field.dataValidation?.constraints?.isRequired) {
        return setHasError(false);
      }

      if (Services.validators.DataValidator.isEmail(value)) {
        return setHasError(false);
      }

      setHasError(true);
      setErrorText('This is not a valid email address');
    }

    if (type === Enums.ValueDataType.POSTCODE) {
      if (!value && !question.field.dataValidation?.constraints?.isRequired) {
        return setHasError(false);
      }

      if (Services.validators.DataValidator.isPostcode(value, constraints)) {
        return setHasError(false);
      }

      setHasError(true);
      setErrorText('This is not a valid postcode');
    }

    if (type === Enums.ValueDataType.PHONE_NUM) {
      if (!value && !question.field.dataValidation?.constraints?.isRequired) {
        return setHasError(false);
      }

      if (Services.validators.DataValidator.isPhoneNum(value, constraints)) {
        return setHasError(false);
      }

      setHasError(true);
      setErrorText('This is not a valid phone number');
    }

    if (type === Enums.ValueDataType.NUMBER) {
      if (Services.validators.DataValidator.isNumber(value, constraints)) {
        return setHasError(false);
      }

      setHasError(true);
      setErrorText('This is not a valid number');
    }
  };

  const canProceed = question.field.dataValidation?.constraints?.isRequired
    ? !!(value && !hasError)
    : !hasError;

  const validate = (event?, canProceed?, markQuestionComplete?) => {
    try {
      isValid(
        question.field.dataValidation?.dataValidationType ||
          Enums.ValueDataType.TEXT,
        value,
        question.field.dataValidation?.constraints,
      );
      handleEnterPress(event, canProceed, markQuestionComplete);
    } catch (error) {
      setHasError(error);
      setErrorText(error.message);
    }
  };

  return (
    <TextInputWrapper>
      <TextStyledSubHeader>{question.question}</TextStyledSubHeader>
      <TextStyledInput
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={validate}
        error={hasError}
        type={question.displayType}
        onKeyUp={(event: KeyboardEvent) => {
          if (event.code === 'Enter') {
            validate(event, canProceed, markQuestionComplete);
          }
        }}
      />
      <WarningWrapper hasError={hasError} errorText={errorText}>
        <Message>
          <Message.Header>{errorText}</Message.Header>
        </Message>
      </WarningWrapper>
      <FeatureButton
        action={markQuestionComplete}
        isDisabled={!canProceed}
        size={FeatureButtonSize.WIDE}
        color={buttonColour}
        text={buttonText}
      />
    </TextInputWrapper>
  );
};

export default Text;
