import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FormContext, FormOutputWithTheme } from '../../../AppContext';
import Devices from '../../../enums/DeviceSize';
import DefaultAvatar from '../../assets/codex-icon.svg';
import FeatureButton, {
  FeatureButtonSize,
} from '../../atoms/FeatureButton/FeatureButton';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';
import MainPage from '../MainPage/MainPage';

export interface MainPageProps {
  isVisible: boolean;
  setIsVisibleValue: React.Dispatch<React.SetStateAction<boolean>>;
  isFormComplete: boolean;
  setIsFormComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainPageHeaderWrapper = styled.div<{ isVisible: boolean }>`
  background-color: ${({ theme }) => theme.colors.system.white};
  ${({ isVisible }) =>
    isVisible
      ? `width: 80vw; margin-left: 20vw;   `
      : `width: 100vw; margin-left: 0; `};
  transition: 0.25s;
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    ${({ isVisible }) => (isVisible ? `width: 100vw; margin-left: 0;` : ``)};
  }
`;

const MainPageWrapper = styled.div<{ isVisible: boolean }>`
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    ${({ isVisible }) =>
      isVisible ? `display: none;` : `width: 100vw; margin-left: 0;`};
  }
`;

const NavToggleWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.standard};
  display: flex;
  align-items: center;
  justify-content: center;
  > h1 {
    margin: ${({ theme }) => theme.margin.xxxlarge};
    font-size: ${({ theme }) => theme.typography.sizes.h2};

    @media only screen and (max-width: ${Devices.LARGE_PHONE}) {
      font-size: ${({ theme }) => theme.typography.sizes.h4};
      margin: ${({ theme }) => theme.margin.large};
    }
    @media only screen and (max-width: ${Devices.NORMAL_PHONE}) {
      font-size: ${({ theme }) => theme.typography.sizes.smaller};
    }
  }
`;

const Heading = styled.div<{ isVisible: boolean; backgroundColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.colors.system.offWhite};
  height: 90px;
  ${({ isVisible }) => (isVisible ? `width: 80vw;` : `width: 100vw;`)};
  transition: 0.25s;
  position: fixed;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.grey};
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    ${({ isVisible }) => (isVisible ? `width: 100vw;` : ``)};
  }
`;

const LogoWrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  justify-content: center;
  z-index: -1;
  position: fixed;
  ${({ isVisible }) => (isVisible ? `width: 85vw;` : `width: 100vw;`)};

  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    position: static;
    margin: ${({ theme }) => theme.margin.standard};
    ${({ isVisible }) => (isVisible ? `width: 10vw` : `width: 10vw;`)};
  }
`;

const ProgressBarWrapper = styled.div`
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    position: fixed;
    top: 90px;
  }
`;

const MainPageContainer: FC<MainPageProps> = ({
  isVisible,
  setIsVisibleValue,
  isFormComplete,
  setIsFormComplete,
}) => {
  const themeContext = useContext(ThemeContext);
  const { formState } = useContext(FormContext);

  const isMobile = window.innerWidth < parseFloat(Devices.TABLET_PORTRAIT);
  useEffect(() => {
    if (isFormComplete) {
      setIsVisibleValue(false);
    }
  }, [isFormComplete]);
  const headerColour = (formState as FormOutputWithTheme)?.theme?.colours
    ?.primary;
  const navButtonColour = (formState as FormOutputWithTheme)?.theme?.colours
    ?.secondary;
  const progressBarColour = (formState as FormOutputWithTheme)?.theme?.colours
    ?.tertiary;

  return (
    <>
      <MainPageHeaderWrapper isVisible={isVisible}>
        <Heading isVisible={isVisible} backgroundColor={headerColour}>
          <NavToggleWrapper>
            {!isFormComplete && (
              <FeatureButton
                action={() => setIsVisibleValue(!isVisible)}
                isActive={isVisible}
                icon={
                  <FontAwesomeIcon
                    icon={faBars}
                    color={themeContext.colors.system.white}
                    size={'lg'}
                  />
                }
                size={FeatureButtonSize.EXTRA_SMALL}
                color={navButtonColour}
              />
            )}
            <h1>{formState.name}</h1>
          </NavToggleWrapper>
          <LogoWrapper isVisible={isVisible}>
            <img
              alt={'Configur Logo'}
              src={formState.theme?.logo || DefaultAvatar}
              height={isMobile ? '30px' : '50px'}
            />
          </LogoWrapper>
          <ProgressBarWrapper>
            <ProgressBar isVisible={isVisible} colour={progressBarColour} />
          </ProgressBarWrapper>
        </Heading>
        <MainPageWrapper isVisible={isVisible}>
          <MainPage
            isFormComplete={isFormComplete}
            setIsFormComplete={setIsFormComplete}
          />
        </MainPageWrapper>
      </MainPageHeaderWrapper>
    </>
  );
};

export default MainPageContainer;
