import { Interfaces } from '@configur-tech/upit-core-types';
import axios from 'axios';
import { FormOutputWithTheme } from '../AppContext';

const READ_ENDPOINT = process.env['REACT_APP_CRUD_FORM'] || '';
const POST_ENDPOINT = process.env['REACT_APP_DATASET_API'] || '';

interface DatasetPostResponse {
  datasetMeta: Interfaces.DatasetMetaOutput;
  entries: Record<string, unknown>[];
}

export default class FormService {
  c;
  public static async getForm(
    projectId: string,
    formId: string,
  ): Promise<FormOutputWithTheme> {
    const result = await axios.get(`${READ_ENDPOINT}${formId}`, {
      params: { projectId, processLists: true },
    });

    return result?.data?.data as FormOutputWithTheme;
  }

  public static async postForm(
    formId: string,
    datasetMetaId: string,
    entries: Record<string, unknown>[],
  ): Promise<DatasetPostResponse> {
    const result = await axios.post(
      `${POST_ENDPOINT}${formId}/${datasetMetaId}`,
      {
        entries,
      },
    );

    return result?.data as DatasetPostResponse;
  }
}
