import React, { FC, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FormContext } from '../AppContext';
import PoweredBy from '../common/atoms/PoweredBy/PoweredBy';
import Loading from '../common/molecules/loading/Loading';
import MainPageContainer from '../common/molecules/MainPageContainer/MainHeaderContainer';
import NavItems from '../common/molecules/NavItems/NavItems';
import Devices from '../enums/DeviceSize';
import FormService from '../services/FormService';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Sidebar = styled.div<{ isVisible: boolean }>`
  position: fixed;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.system.grey};
  background-color: ${({ theme }) => theme.colors.system.offWhite};
  ${({ isVisible }) => (isVisible ? `width: 20vw;` : `width: 0;`)};
  transition: width 0.25s;
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    ${({ isVisible }) =>
      isVisible ? `width: 100vw; margin-top: 90px;` : `width: 0;`};
  }
`;

const PoweredByWrapper = styled.div`
  right: ${({ theme }) => theme.margin.xlarge};
  position: fixed;
  bottom: ${({ theme }) => theme.margin.xlarge};
  z-index: 99;
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    display: none;
  }
`;

const FormContainer: FC = () => {
  const params = useParams();
  const history = useHistory();

  const { formState, setFormState } = useContext(FormContext);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false);

  useEffect(() => {
    if (params.projectId && params.formId) {
      (async () => {
        try {
          const form = await FormService.getForm(
            params.projectId,
            params.formId,
          );
          setFormState(form);
        } catch (err) {
          history.push('/');
        }
      })();
    }
  }, []);

  if (!formState._id) {
    return <Loading loading={true} text={'Loading Form...'} />;
  }

  return (
    <>
      <Helmet>
        <title>{`${formState.name || 'Form'} - Powered by Configur`}</title>
      </Helmet>

      <Wrapper>
        <Sidebar isVisible={isVisible}>
          <NavItems setIsVisible={setIsVisible} />
        </Sidebar>
        <MainPageContainer
          isVisible={isVisible}
          setIsVisibleValue={setIsVisible}
          isFormComplete={isFormComplete}
          setIsFormComplete={setIsFormComplete}
        />
      </Wrapper>

      <PoweredByWrapper>
        <PoweredBy />
      </PoweredByWrapper>
    </>
  );
};

export default FormContainer;
