import { Enums, Services } from '@configur-tech/upit-core-types';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import Devices from '../../../enums/DeviceSize';
import QuestionProps from '../../../interfaces/QuestionProps';
import { InputWrapper, TextStyledSubHeader } from '../../../Theme';
import FeatureButton, {
  FeatureButtonSize,
} from '../../atoms/FeatureButton/FeatureButton';

interface DateTimePickerProps extends QuestionProps {
  dateValue?: string;
  dateFormat?: Enums.DateFormat | string;
  popperPlacement?: string;
  popperModifiers?: Record<string, unknown> | null;
}

const timeRequiredFormats = [
  Enums.DateFormat.TIME_SIMPLE,
  Enums.DateFormat.TIME_WITH_SECONDS,
  Enums.DateFormat.TIME_24_SIMPLE,
  Enums.DateFormat.TIME_24_WITH_SECONDS,
  Enums.DateFormat.DATETIME_SHORT,
  Enums.DateFormat.DATETIME_SHORT_NO_COMMA,
  Enums.DateFormat.DATETIME_MED,
  Enums.DateFormat.DATETIME_FULL,
  Enums.DateFormat.DATETIME_HUGE,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS,
  Enums.DateFormat.DATETIME_SHORT_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_MED_WITH_SECONDS,
  Enums.DateFormat.DATETIME_FULL_WITH_SECONDS,
  Enums.DateFormat.DATETIME_HUGE_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_SHORT,
  Enums.DateFormat.DATETIME_24_SHORT_NO_COMMA,
  Enums.DateFormat.DATETIME_24_MED,
  Enums.DateFormat.DATETIME_24_FULL,
  Enums.DateFormat.DATETIME_24_HUGE,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_SHORT_WITH_SECONDS_NO_COMMA,
  Enums.DateFormat.DATETIME_24_MED_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_FULL_WITH_SECONDS,
  Enums.DateFormat.DATETIME_24_HUGE_WITH_SECONDS,
];

const timeOnlyFormats = [
  Enums.DateFormat.TIME_SIMPLE,
  Enums.DateFormat.TIME_WITH_SECONDS,
  Enums.DateFormat.TIME_24_SIMPLE,
  Enums.DateFormat.TIME_24_WITH_SECONDS,
];

const Wrapper = styled.div<{ active }>`
  align-items: center;
  background: #fff;

  width: calc(100% - 20px);
  height: 10vh;
  margin: ${({ theme }) => theme.margin.standard};

  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    width: 90vw;
  }

  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.colors.general.blue : theme.colors.system.grey};
  border-radius: ${({ theme }) => theme.borders.radius};

  > div {
    display: flex;
    width: 100%;
    min-height: 100%;

    > div {
      > input {
        text-align: left !important;
        font-size: ${({ theme }) => theme.typography.sizes.h3};
        vertical-align: center;

        border: none;
        width: 100%;
        height: 100%;

        &:hover {
          cursor: pointer;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }

  .react-datepicker__portal {
    position: absolute;
  }

  .react-datepicker {
    font-size: ${({ theme }) => theme.typography.sizes.p};
    box-sizing: content-box;
    @media only screen and (max-width: ${Devices.LARGE_PHONE}) {
      width: 250px;
      font-size: ${({ theme }) => theme.typography.sizes.p};
    }

    button {
      box-sizing: border-box;
    }
  }
  .react-datepicker__month-container {
    width: inherit;
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__header {
    padding-top: ${({ theme }) => theme.padding.standard};
  }
  .react-datepicker__month {
    font-size: ${({ theme }) => theme.typography.sizes.h4};
    @media only screen and (max-width: ${Devices.LARGE_PHONE}) {
      font-size: ${({ theme }) => theme.typography.sizes.p};
    }
    @media only screen and (max-width: ${Devices.NORMAL_PHONE}) {
      font-size: ${({ theme }) => theme.typography.sizes.small};
    }
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-evenly;
  }

  .react-datepicker__day {
    padding: ${({ theme }) => theme.padding.small};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.general.blue};
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-evenly;
    @media only screen and (max-width: ${Devices.NORMAL_PHONE}) {
      font-size: ${({ theme }) => theme.typography.sizes.smaller};
    }
  }
  .react-datepicker__current-month {
    font-size: ${({ theme }) => theme.typography.sizes.h4};
    width: inherit;
    @media only screen and (max-width: ${Devices.NORMAL_PHONE}) {
      font-size: ${({ theme }) => theme.typography.sizes.smaller};
    }
  }
`;

const DateInputWrapper = styled(InputWrapper)`
  > .ui.input {
    height: 105px;
    @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
      height: 85px;
    }
    > input {
      width: 100%;
      height: 100%;
      padding: ${({ theme }) => theme.padding.large};
    }
  }
`;

const DatePickerSelector: FC<DateTimePickerProps> = ({
  question,
  finalQuestion,
  dateFormat = Enums.DateFormat.DATE_SHORT,
  value,
  dateValue,
  setValue,
  markQuestionComplete,
  popperPlacement,
  popperModifiers,
  buttonColour,
}) => {
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);

  const buttonText = finalQuestion ? 'Finish' : 'Next';

  const canProceed = question.field.dataValidation?.constraints?.isRequired
    ? !!(value && !hasError)
    : !hasError;

  const isMobile = window.innerWidth < parseFloat(Devices.LARGE_PHONE);

  const isValid = (value: string | number | boolean | string[]) => {
    if (!value && !question.field.dataValidation?.constraints?.isRequired) {
      return setHasError(false);
    }

    return setHasError(Services.validators.DataValidator.isValidDate(value));
  };

  useEffect(() => {
    if (value) {
      isValid(value);
    }
  }, []);

  return (
    <DateInputWrapper>
      <TextStyledSubHeader>{question.question}</TextStyledSubHeader>
      <Wrapper active={calendarOpen}>
        <DatePicker
          dateFormat={dateFormat}
          selected={
            value && dateValue
              ? new Date(
                  DateTime.fromFormat(
                    String(value),
                    dateFormat,
                  ).toString() as string,
                )
              : null
          }
          withPortal={isMobile}
          value={value}
          placeholderText={'Select a date'}
          showPopperArrow={true}
          popperPlacement={popperPlacement || 'bottom'}
          popperModifiers={
            popperModifiers != null
              ? undefined
              : popperModifiers || {
                  offset: {
                    enabled: true,
                    offset: '-5px, 20px',
                  },
                }
          }
          onChange={(e) =>
            setValue(DateTime.fromJSDate(e).toFormat(dateFormat))
          }
          onCalendarClose={() => setCalendarOpen(false)}
          onCalendarOpen={() => setCalendarOpen(true)}
          showTimeSelect={timeRequiredFormats.includes(
            dateFormat as Enums.DateFormat,
          )}
          timeIntervals={1}
          showTimeSelectOnly={timeOnlyFormats.includes(
            dateFormat as Enums.DateFormat,
          )}
        />
      </Wrapper>
      <FeatureButton
        action={markQuestionComplete}
        isDisabled={!canProceed}
        size={FeatureButtonSize.WIDE}
        color={buttonColour}
        text={buttonText}
      />
    </DateInputWrapper>
  );
};

export default DatePickerSelector;
