import React, { FC } from 'react';
import styled from 'styled-components';
import { StyledH1 } from '../../../Theme';
import DefaultLoadingIcon from '../../assets/default-loading-icon.gif';
import FadeIn from '../../atoms/fade-in/FadeIn';

export interface LoadingProps {
  loading?: boolean;
  image?: React.ReactElement;
  text?: string;
}

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  width: 100vw;
  height: 100vh;

  background-color: ${({ theme }) => `${theme.colors.system.white}`};
  z-index: 999;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingIcon = styled.img`
  width: 150px;
  height: 150px;
`;

const LoadingText = styled(StyledH1)`
  margin-top: ${({ theme }) => theme.margin.large};
`;

const Loading: FC<LoadingProps> = ({ loading, image, text }) => {
  if (!loading) {
    return null;
  }

  return (
    <FadeIn>
      <Overlay>
        <Wrapper>
          {image || <LoadingIcon src={DefaultLoadingIcon} alt={'Loading'} />}
          <LoadingText>{text || 'Loading...'}</LoadingText>
        </Wrapper>
      </Overlay>
    </FadeIn>
  );
};

export default Loading;
