import { Enums } from '@configur-tech/upit-core-types';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Progress } from 'semantic-ui-react';
import styled from 'styled-components';
import { FormContext } from '../../../AppContext';
import Devices from '../../../enums/DeviceSize';

export interface ProgressBarProps {
  isVisible: boolean;
  colour?: string;
}

const ProgressWrapper = styled.div<{ isVisible: boolean; colour?: string }>`
  width: 20vw;
  padding: ${({ theme }) => theme.padding.xlarge};
  > .ui.progress:last-child {
    margin: 0;
    > div {
      background-color: ${({ colour, theme }) =>
        colour || theme.colors.general.green};
    }
  }
  @media only screen and (max-width: ${Devices.TABLET_PORTRAIT}) {
    padding: 0;
    width: 100vw;
    ${({ isVisible }) => (isVisible ? `display:none` : ``)};
  }
`;

const ProgressBar: FC<ProgressBarProps> = ({ isVisible, colour }) => {
  const { formState } = useContext(FormContext);
  const [progress, setProgress] = useState(0);
  const activeQuestionAnchor = useLocation().hash.substring(1);

  const questionDetails = formState.questionGroups
    .map((questionGroup) => questionGroup.questions)
    .flat();

  useEffect(() => {
    const numberOfQuestions = questionDetails.filter(
      (question) =>
        question.displayType !== Enums.FormQuestionDisplayType.HIDDEN,
    ).length;
    const numberOfCompleted = questionDetails.filter(
      (x) =>
        x.status === Enums.FormQuestionStatus.COMPLETED &&
        x.displayType !== Enums.FormQuestionDisplayType.HIDDEN,
    ).length;
    const newProgress = (numberOfCompleted / numberOfQuestions) * 100;

    setProgress(newProgress);
  }, [activeQuestionAnchor, questionDetails]);

  return (
    <ProgressWrapper isVisible={isVisible} colour={colour}>
      <Progress percent={progress} size={'medium'} />
    </ProgressWrapper>
  );
};

export default ProgressBar;
